import React from 'react';

import {
    Layout,
    useTranslate,
} from '@solublestudio/gatsby-theme-soluble-source';
import { ErrorSection } from '@solublestudio/cuerva-design-system';

export default function NotFoundPage({ pageContext }) {
    const language = pageContext?.language;
    const t = useTranslate();

    return (
        <Layout showFooter={false} isDark={true}>
            <ErrorSection
                title={'404'}
                text={t['404.title.text']?.text}
                bgCover={false}
                theme=""
                cta={{
                    label: t['404.cta.label']?.text,
                    href: language ? `/${language}/` : `/`,
                }}
                withDelay={false}
            />
        </Layout>
    );
}
